<template>
  <div class="col-lg-12">
    <div class="row">
      <b-card title="Account Balance" class="col-md-3 mr-1">
        <!-- show funds available in client wallet here -->
        <h4 style="color:#5e50ee;">{{ balance }}</h4>
      </b-card>
      <b-card title="Accounts" class="col-md-3 mr-1">
        <!-- show number of accounts that the client has -->
        <h4 style="color:#5e50ee;">
          {{ totalAccountsNumber }} account(s)
        </h4>
      </b-card>
      <b-card title="Orders" class="col-md-3 mr-1" v-if="orders.length">
        <b-link :to="{name:'orders'}">
          <h4 style="color:#5e50ee;">
            {{ orders.length }} Placed Orders
            <feather-icon icon="ExternalLinkIcon" size="15" />
          </h4>
        </b-link>
      </b-card>
    </div>
  </div>
</template>

<script>
import { BCard, BLink } from 'bootstrap-vue'
import { mapGetters } from 'vuex'

export default {
  props: ['totalAccountsNumber'],
  components: {
    BCard,
    BLink
  },
  computed: {
    balance() {
      if (this.user.clients[0].customData.ppc && this.user.clients[0].customData.ppc.subAccount.currencyBalances.EUR.balance.available) {
        return `${parseInt(this.user.clients[0].customData.ppc.subAccount.currencyBalances.EUR.balance.available, 10) / 100} EUR`
      }
      return '/'
    },
    ...mapGetters({
      user: 'userInfo',
      orders: 'orderInfo',
    }),
  },
  mounted () {
    this.$store.dispatch('fetchOrders')
    this.$store.dispatch('fetchUser')
  }
}
</script>

