<template>
  <div>
    <!-- modal - fund acount-->
    <b-modal
        id="fund-account"
        modal-class="modal-primary"
        title="Fund Account"
    >
      <b-form>
        <b-form-group
            label="Account Name"
        >
          <b-form-input
              :value="editedAccount.name"
              readonly
              name="company"
              placeholder="Company name"
          />
        </b-form-group>
        <b-form-group
            label="Amount (€)"
        >
          <b-form-input
              v-model.number="fundAccountAmount"
              type="number"
              min="0" oninput="validity.valid||(value='');"
              placeholder="Amount to deposit"
          />
        </b-form-group>
      </b-form>
      <template #modal-footer>
        <b-button variant="primary" :disabled="!fundAccountAmount" @click="fundAccount">Fund</b-button>
      </template>
    </b-modal>
    <b-modal
        id="login-info"
        modal-class="modal-primary"
        title="RDP Login Info"
    >
      <b-form v-if="editedAccount && editedAccount.rdp">
        <b-form-group
            label="Url / IP"
            v-if="editedAccount.rdp.url"
        >
          <strong>{{ editedAccount.rdp.url }}</strong>
        </b-form-group>
        <b-form-group
            label="Username"
        >
          <strong>{{ editedAccount.rdp.username }}</strong>
        </b-form-group>
        <b-form-group
            label="Password"
        >
          <strong>{{ editedAccount.rdp.password }}</strong>
        </b-form-group>
      </b-form>
      <template #modal-footer>
        <b-button variant="primary" @click="$bvModal.hide('login-info')">Close</b-button>
      </template>
    </b-modal>
    <b-modal
        id="cvv"
        modal-class="modal-primary"
        title="Card Security"
    >
      <b-form>
        <b-form-group
            label="Static Pin"
        >
          <div v-if="loading" class="loader"></div>
          <strong v-else-if="cvv.data">{{ cvv.data.staticPin }}</strong>
          <div v-else><p>issue loading the data, please try again later</p></div>
        </b-form-group>
      </b-form>
      <template #modal-footer>
        <b-button variant="primary" @click="$bvModal.hide('3ds')">
          Close
        </b-button>
      </template>
    </b-modal>
    <b-modal
        id="terminate-confirmation"
        modal-class="modal-primary"
        title="Termination confirmation"
    >
      <b-form>
        Are you sure you want to terminate this account?
        <ul class="mt-1" style="width: 70%;">
          <li>
            <span>Account:</span>
            <span class="float-right">{{ editedAccount.name }}</span>
          </li>
          <li>
            <span>Site:</span>
            <span class="float-right">{{ editedAccount.site ? editedAccount.site.punyDomain : '/' }}</span>
          </li>
          <li>
            <span>MCC id:</span>
            <span class="float-right">{{ editedAccount.mccId }}</span>
          </li>
          <li>
            <span>Status:</span>
            <span class="float-right">{{ editedAccount.status }}</span>
          </li>
        </ul>
      </b-form>
      <template #modal-footer>
        <b-button variant="primary" @click="terminateAccount()">Terminate Account</b-button>
      </template>
    </b-modal>
    <information-card :totalAccountsNumber="Object.values(accounts).length"/>
    <!-- search input -->
    <div class="custom-search d-flex flex-row justify-content-end align-items-center">
      <b-button
          v-ripple.400="'rgba(255, 255, 255, 0.15)'"
          :to="{ name: 'buy-account' }"
          variant="outline-primary"
          class="ml-1 mb-1"
      >
        Buy New Account
      </b-button>
    </div>
    <vue-good-table
        :columns="columns"
        :rows="accounts"
        :rtl="direction"
        :pagination-options="{
        enabled: true,
        perPage:pageLength
      }"
    >
      <template
          slot="table-row"
          slot-scope="props"
      >
        <span v-if="props.column.field === 'status'">
          <b-badge :variant="'light-info'">
            {{ props.row.status }}
          </b-badge>
        </span>
        <span v-else-if="props.column.field === 'multiLogin.adActive'">
          <b-badge :variant="props.row.multiLogin.adActive ? 'light-success' : 'light-danger'">
            {{ props.row.multiLogin.adActive }}
          </b-badge>
        </span>

        <span v-else-if="props.column.field === 'actions'" style="display: flex; justify-content: space-between;">
          <feather-icon v-if="props.row.multiLogin && props.row.status == 'active'" size="16" v-b-modal.login-info v-b-tooltip="'See Login Info'"
                        icon="UnlockIcon" @click="editedAccount = props.row" :disabled="!props.row.multiLogin"/>
          <feather-icon v-if="props.row.status != 'suspended'" size="16" icon="TrashIcon" v-b-tooltip="'Terminate Account'"
                        v-b-modal.terminate-confirmation @click="editedAccount = props.row"/>
        </span>
        <span v-else-if="props.column.field === 'safe-site'">
          {{ props.row.site ? props.row.site.punyDomain : '/' }}
          <div v-if="props.row.site">
            <a v-if="props.row.site.punyDomain" :href="'https://' + props.row.site.punyDomain"
               v-b-tooltip="'Visit site'">
              <feather-icon size="16" icon="LinkIcon" class="cursor-pointer"/>
            </a>
            <feather-icon size="16" icon="UnlockIcon" class="cursor-pointer"
                          @click="adminLogin(props.row.site.punyDomain)" v-b-tooltip="'Admin login'"/>
          </div>
        </span>
        <span v-else>
          {{ props.formattedRow[props.column.field] }}
        </span>
      </template>

      <!-- pagination -->
      <template
          slot="pagination-bottom"
          slot-scope="props"
      >
        <div class="d-flex justify-content-between flex-wrap">
          <div class="d-flex align-items-center mb-0 mt-1">
            <span class="text-nowrap ">
              Showing 1 to
            </span>
            <b-form-select
                v-model="pageLength"
                :options="['3','5','10']"
                class="mx-1"
                @input="(value)=>props.perPageChanged({currentPerPage:value})"
            />
            <span class="text-nowrap"> of {{ props.total }} entries </span>
          </div>
          <div>
            <b-pagination
                :value="1"
                :total-rows="props.total"
                :per-page="pageLength"
                first-number
                last-number
                align="right"
                prev-class="prev-item"
                next-class="next-item"
                class="mt-1 mb-0"
                @input="(value)=>props.pageChanged({currentPage:value})"
            >
              <template #prev-text>
                <feather-icon
                    icon="ChevronLeftIcon"
                    size="18"
                />
              </template>
              <template #next-text>
                <feather-icon
                    icon="ChevronRightIcon"
                    size="18"
                />
              </template>
            </b-pagination>
          </div>
        </div>
      </template>
    </vue-good-table>
  </div>
</template>

<script>
import {
  BAvatar, BBadge, BPagination, BFormGroup, BFormInput, BFormSelect, BButton, BForm,
} from 'bootstrap-vue'
import { VueGoodTable } from 'vue-good-table'
import store from '@/store/index'
import ToastificationContent from '@core/components/toastification/ToastificationContent'
import InformationCard from '@/layouts/components/informationCards/informationCard.vue'
import Ripple from 'vue-ripple-directive'
import axios from '@/auth/http'

export default {
  name: 'AllAccounts',
  components: {
    VueGoodTable,
    BAvatar,
    BBadge,
    BPagination,
    BFormGroup,
    BFormInput,
    BFormSelect,
    BButton,
    BForm,
    InformationCard
  },
  directives: {
    Ripple,
  },
  data () {
    return {
      accounts: [],
      editedAccount: {},
      fundAccountAmount: 0,
      pageLength: 10,
      dir: false,
      columns: [
        {
          label: 'Account',
          field: 'name',
        },
        {
          label: 'MCC ID',
          field: 'mccId',
        },
        {
          label: 'Status',
          field: 'status',
        },
        {
          label: 'Site',
          field: 'safe-site',
        },
        {
          label: '',
          field: 'actions',
        },
      ],
      searchTerm: '',
      status: [
        {
          0: 'Current',
          1: 'Current',
          2: 'Professional',
          3: 'Rejected',
          4: 'Resigned',
          5: 'Applied',
        },
        {
          0: 'light-primary',
          1: 'light-primary',
          2: 'light-success',
          3: 'light-danger',
          4: 'light-warning',
          5: 'light-info',
        }],
      cvv: '',
      loading: false,
    }
  },
  computed: {
    statusVariant () {
      const statusColor = {
        /* eslint-disable key-spacing */
        Current: 'light-primary',
        Professional: 'light-success',
        Rejected: 'light-danger',
        Resigned: 'light-warning',
        Applied: 'light-info',
        /* eslint-enable key-spacing */
      }

      return status => statusColor[status]
    },
    direction () {
      if (store.state.appConfig.isRTL) {
        // eslint-disable-next-line vue/no-side-effects-in-computed-properties
        this.dir = true
        return this.dir
      }
      // eslint-disable-next-line vue/no-side-effects-in-computed-properties
      this.dir = false
      return this.dir
    },
  },
  mounted () {
    this.fetchAccounts()
  },
  methods: {
    fetchAccounts () {
      this.$store.dispatch('fetchAccounts').then(() => {
        this.accounts = this.$store.state.accountsStore.accounts
      }).catch(error => {
        let msg = error.message
        if (error.response) {
          msg = error.response.data.msg
        }
        this.$toast({
          component: ToastificationContent,
          props: {
            title: msg,
            icon: 'InfoIcon',
            variant: 'danger',
          },
        }, {
          position: 'top-center',
        })
      })
    },
    fundAccount () {
      if (!Object.values(this.editedAccount.cards).length) {
        this.$toast({
          component: ToastificationContent,
          props: {
            title: 'No cards ares linked to the chosen account',
            variant: 'warning',
          },
        })
        return
      }

      this.$store.dispatch('fundAccount', {
        amount: this.fundAccountAmount,
        cardId: this.editedAccount.cards[0].id,
      }).then(() => {
        this.$toast({
          component: ToastificationContent,
          props: {
            title: 'Account Funded',
            icon: 'CheckIcon',
            variant: 'success',
          },
        })
        this.$bvModal.hide('fund-account')
        this.$store.dispatch('fetchAccounts')
        this.$store.dispatch('fetchUser')
      }).catch(error => {
        let msg = error.message
        if (error.response) {
          msg = error.response.data.msg
        }
        this.$toast({
          component: ToastificationContent,
          props: {
            title: msg,
            icon: 'InfoIcon',
            variant: 'danger',
          },
        })
      })
    },
    async getCvv (id) {
      this.loading = true
      try {
        this.cvv = await axios.get(`/ppc/cards/${id}/3ds`)
        this.loading = false
      } catch (e) {
        this.cvv = e
        this.loading = false
      }
    },
    async adminLogin (domain) {
      try {
        const url = await axios.post(`/pbn/site/${domain}/sso/session`)
        window.open(url.data.url, '_blank')
      } catch (e) {}
    },
    terminateAccount () {
      this.$store.dispatch('terminateAccount', this.editedAccount.id)
        .then(resp => {
          this.fetchAccounts()
          this.$toast({
            component: ToastificationContent,
            props: {
              title: 'Account Terminated',
              icon: 'CheckIcon',
              variant: 'success',
            },
          })
          this.$bvModal.hide('terminate-confirmation')
        })
        .catch(error => {
          let msg = error.message
          if (error.response) {
            msg = error.response.data.msg
          }
          this.$toast({
            component: ToastificationContent,
            props: {
              title: msg,
              icon: 'InfoIcon',
              variant: 'danger',
            },
          }, {
            position: 'top-right',
          })})
    },
  },
}
</script>

<style lang="scss">
@import '@core/scss/vue/libs/vue-good-table.scss';

</style>
